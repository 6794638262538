import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import classnames from 'classnames';
import ActionModal from '../action-modal';
import Iframe from '../iframe';
import Media from '../media';
import Image from '../image';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';

const ModalFactory = ({ title, className, picture, subtitles, action, namespace }) => (
  <div className={className}>
    {picture && (
      <Media className={classnames(`${namespace}__item`, className)} title={title} subtitles={subtitles}>
        <Image src={picture.url.src} alt={picture.alt} />
      </Media>
    )}
    {action && (
      <ActionModal
        label={action.label}
        modalTitle={action.modal_title}
        modalClassName="ui-pdp-iframe-modal"
        className="ui-pdp-action__action"
        linkClassName={`${namespace}__link`}
        track={action.track}
        url={action.target}
        closeModalLabel={action.close_modal_label}
      >
        <Iframe
          title={action.label ? action.label.text : null}
          src={action.target}
          onMessage={onIframeModalResize(
            'vip:modal-content:loaded',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
          )}
          scrolling="no"
        />
      </ActionModal>
    )}
  </div>
);

ModalFactory.propTypes = {
  action: shape({
    target: string.isRequired,
    html_target: string.isRequired,
    label: shape({
      text: string.isRequired,
    }).isRequired,
  }),
  className: string,
  picture: shape({
    id: string,
    url: {
      src: string,
    },
    alt: string,
  }),
  namespace: string,
  title: shape({
    text: string.isRequired,
  }),
  subtitles: arrayOf(
    shape({
      text: string.isRequired,
    }).isRequired,
  ),
};

ModalFactory.defaultProps = {
  className: null,
  picture: null,
  subtitles: null,
  title: null,
  namespace: '',
  action: null,
};

export default ModalFactory;
