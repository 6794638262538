import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import classnames from 'classnames';
import ModalFactory from '../factory/modal-factory';

const namespace = 'ui-pdp-additional-info';

const AdditionalInfo = ({ title, className, picture, subtitles, action }) => (
  <ModalFactory
    action={action}
    title={title}
    picture={picture}
    subtitles={subtitles}
    namespace={namespace}
    className={classnames(namespace, className)}
  />
);

AdditionalInfo.propTypes = {
  action: shape({
    target: string.isRequired,
    html_target: string.isRequired,
    label: shape({
      text: string.isRequired,
    }).isRequired,
  }).isRequired,
  className: string,
  picture: shape({
    id: string,
    url: {
      src: string,
    },
    alt: string,
  }),
  title: shape({
    text: string.isRequired,
  }).isRequired,
  subtitles: arrayOf(
    shape({
      text: string.isRequired,
    }).isRequired,
  ).isRequired,
};

AdditionalInfo.defaultProps = {
  className: null,
  picture: null,
};

export default React.memo(AdditionalInfo);
